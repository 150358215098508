exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-documentation-template-js": () => import("./../../../src/templates/documentation_template.js" /* webpackChunkName: "component---src-templates-documentation-template-js" */),
  "component---src-templates-download-js": () => import("./../../../src/templates/download.js" /* webpackChunkName: "component---src-templates-download-js" */),
  "component---src-templates-help-js": () => import("./../../../src/templates/help.js" /* webpackChunkName: "component---src-templates-help-js" */),
  "component---src-templates-imprint-js": () => import("./../../../src/templates/imprint.js" /* webpackChunkName: "component---src-templates-imprint-js" */),
  "component---src-templates-in-app-purchase-js": () => import("./../../../src/templates/in-app-purchase.js" /* webpackChunkName: "component---src-templates-in-app-purchase-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-markdown-template-js": () => import("./../../../src/templates/markdown_template.js" /* webpackChunkName: "component---src-templates-markdown-template-js" */),
  "component---src-templates-partner-grandtotal-js": () => import("./../../../src/templates/partner-grandtotal.js" /* webpackChunkName: "component---src-templates-partner-grandtotal-js" */),
  "component---src-templates-partner-lexware-office-discount-js": () => import("./../../../src/templates/partner-lexware-office-discount.js" /* webpackChunkName: "component---src-templates-partner-lexware-office-discount-js" */),
  "component---src-templates-partner-lexware-office-js": () => import("./../../../src/templates/partner-lexware-office.js" /* webpackChunkName: "component---src-templates-partner-lexware-office-js" */),
  "component---src-templates-partner-sevdesk-discount-js": () => import("./../../../src/templates/partner-sevdesk-discount.js" /* webpackChunkName: "component---src-templates-partner-sevdesk-discount-js" */),
  "component---src-templates-partner-sevdesk-js": () => import("./../../../src/templates/partner-sevdesk.js" /* webpackChunkName: "component---src-templates-partner-sevdesk-js" */),
  "component---src-templates-plugins-js": () => import("./../../../src/templates/plugins.js" /* webpackChunkName: "component---src-templates-plugins-js" */),
  "component---src-templates-pricing-js": () => import("./../../../src/templates/pricing.js" /* webpackChunkName: "component---src-templates-pricing-js" */),
  "component---src-templates-release-notes-js": () => import("./../../../src/templates/release-notes.js" /* webpackChunkName: "component---src-templates-release-notes-js" */),
  "component---src-templates-release-notes-template-js": () => import("./../../../src/templates/release_notes_template.js" /* webpackChunkName: "component---src-templates-release-notes-template-js" */),
  "component---src-templates-shortcut-gallery-js": () => import("./../../../src/templates/shortcut-gallery.js" /* webpackChunkName: "component---src-templates-shortcut-gallery-js" */),
  "component---src-templates-who-we-are-js": () => import("./../../../src/templates/who-we-are.js" /* webpackChunkName: "component---src-templates-who-we-are-js" */)
}

